<template>
  <CommonCard>
    <CommondLoading
      v-if="loadingModal"
      :loadingImage="dataLoading.inter"
      :show="loadingModal"
    />
    <v-card-text
      style="padding: var(--Spacing-Spacing-lg, 32px) var(--Spacing-spacing-3xmd, 32px);"
    >
      <v-row dense>
        <div cols="2">
          <v-btn large icon text @click="$router.go(-1)">
            <v-icon color="#00a7e4" medium>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <v-col cols="10">
          <span class="subtitulo-header">Nuevo lead</span>
        </v-col>
      </v-row>
      <v-row>
        <commond-form
          :request="formData"
          :route="route"
          :run="run"
          :action="action"
          @cancel="run = false"
          @success="closeComponent"
          @afterError="run = false"
        >
          <template v-slot:data>
            <v-form ref="validateForm" :lazy-validation="true">
              <v-col cols="12">
                <MedioData
                  :getInfo="getInfo"
                  @updateMedioData="updateMedioData"
                />
              </v-col>
              <v-col cols="12">
                <PersonalData
                  :getInfo="getInfo"
                  @updatePersonalData="updatePersonalData"
                />
              </v-col>
              <v-col cols="12">
                <ContactData
                  :getInfo="getInfo"
                  @updateContactData="updateContactData"
                />
              </v-col>
            </v-form>
          </template>
        </commond-form>
        <v-col cols="12" style="text-align: end;">
          <v-btn
            rounded
            dark
            outlined
            color="#00a7e4"
            @click="changeComponent"
            style="margin-right: 11px;"
          >
            <span>
              Cancelar
            </span>
          </v-btn>
          <v-btn rounded dark class="common-botton" @click="saveData">
            <span>
              Guardar
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </CommonCard>
</template>

<script>
import dataLoading from "@/helpers/data/data-loading-gif.json";

export default {
  components: {
    CommonCard: () => import("@/components/commonComponents/CommonCard.vue"),
    PersonalData: () => import("./Leads/PersonalData.vue"),
    MedioData: () => import("./Leads/MedioData.vue"),
    ContactData: () => import("./Leads/ContactData.vue"),
    CommondForm: () => import("@/components/commonComponents/CommondForm.vue"),
    CommondLoading: () =>
      import("@/components/commonComponents/CommondLoading.vue"),
  },

  data() {
    return {
      dataLoading: dataLoading,
      loadingIframe: true,
      medioData: {},
      PersonalData: {},
      contactData: {},
      formData: {
        leadPortal: true,
      },
      route: "/v1/lead-eci",
      action: "POST",
      run: false,
      getInfo: false,
      loadingModal: false,
    };
  },
  methods: {
    frameLoaded() {
      this.loadingIframe = false;
    },
    frameError() {
      console.log("Error al cargar el iframe.");
    },
    updateMedioData(val) {
      this.medioData = val;
    },
    updatePersonalData(val) {
      this.personalData = val;
    },
    updateContactData(val) {
      this.contactData = val;
    },
    async saveData() {
      if (this.$refs.validateForm.validate()) {
        this.loadingModal = true;
        this.getInfo = true;
        await this.waitForData();
        this.getInfo = false;
        this.loadingModal = false;
        this.formData = {
          ...this.medioData,
          ...this.personalData,
          ...this.contactData,
          ...this.formData
        };
        Object.keys(this.formData).map((e) => {
          if (this.formData[e] == "") delete this.formData[e];
        });
        this.run = true;
      }
    },
    async waitForData() {
      while (
        Object.keys(this.medioData).length === 0 ||
        Object.keys(this.personalData).length === 0 ||
        Object.keys(this.contactData).length === 0
      ) {
        await this.sleep(100);
      }
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    closeComponent(response) {
      this.$router.push({
        path:"/segurify/leads-segurify/lead/" + response.data.uuid_lead_segurify,
        query: {
          llamadaMasiva: false,
        }})
    },
  },
};
</script>

<style>
.card-loading {
  padding: 32px;
  max-width: 100%;
  min-height: 100px;
  display: flex; /* Usamos flexbox */
  justify-content: center; /* Centramos horizontalmente */
  align-items: center; /* Centramos verticalmente */
}
.center-image {
  margin: auto; /* Centro horizontal */
  display: block; /* Para que el margen automático funcione */
}
</style>
